<template>
  <div class="gallery">
    <div v-if="!withoutMainPhoto" class="gallery-main">
      <client-only>
        <carousel
          :key="key"
          :items="1"
          :start-position="startPosition"
          :show-nav="images.length > 1"
          :change="imgMain"
          :keyboard="true"
          @changed="changedMain"
        >
          <b-image
            v-for="(image, index) in images"
            :key="image.id"
            ratio="1by1"
            :src="getImageBySize(image.file, zoomed ? 'huge' : 'superaHuge').src"
            :webp-fallback="getImageBySize(image.file, zoomed ? 'huge' : 'superaHuge').webpFallback"
            :alt="image.file.alt ? image.file.alt : name"
            class="skeleton-image-placeholder carousel-main-photo"
            :class="{'zoom': zoom}"
            lazy
            @click.native="showImage(index)"
          >
            <template #placeholder>
              <b-skeleton />
            </template>
          </b-image>
        </carousel>
        <template slot="placeholder">
          <b-image
            v-if="images.length > 0"
            ratio="1by1"
            :src="getImageBySize(images[0].file, 'huge').src"
            :webp-fallback="getImageBySize(images[0].file, 'huge').webpFallback"
            :alt="images[0].file.alt ? images[0].file.alt : name"
            class="skeleton-image-placeholder carousel-main-photo"
            lazy
          >
            <template #placeholder>
              <b-skeleton />
            </template>
          </b-image>
        </template>
      </client-only>
    </div>

    <div v-if="!withoutThumbnails" class="gallery-thumbnails">
      <client-only>
        <carousel
          :key="key"
          :items="thumbnails.page"
          :start-position="startPositionThumbnail"
          :change="imgThumbnail"
          :show-nav="images.length > thumbnails.page"
          :responsive="responsive"
          @changed="changedThumbnail"
        >
          <template v-if="images.length > thumbnails.page" slot="prev">
            <span class="prev">
              <b-icon icon="chevron-left" />
            </span>
          </template>
          <b-image
            v-for="(image, index) in images"
            :key="image.id"
            ratio="1by1"
            :src="getImageBySize(image.file, 'medium').src"
            :webp-fallback="getImageBySize(image.file, 'medium').webpFallback"
            :alt="image.file.alt ? image.file.alt : name"
            class="skeleton-image-placeholder carousel-thumbnail-photo"
            lazy
            @click.native="setImgMain(index)"
          >
            <template #placeholder>
              <b-skeleton />
            </template>
          </b-image>
          <template v-if="images.length > thumbnails.page" slot="next">
            <span class="next">
              <b-icon icon="chevron-right" />
            </span>
          </template>
        </carousel>
        <template slot="placeholder">
          <div class="carousel-thumbnail-placeholder">
            <div
              v-for="(image, index) in images"
              :key="image.id"
            >
              <b-image
                v-if="index < thumbnails.page"
                ratio="1by1"
                :src="getImageBySize(image.file, 'medium').src"
                :webp-fallback="getImageBySize(image.file, 'medium').webpFallback"
                :alt="image.file.alt ? image.file.alt : name"
                class="skeleton-image-placeholder carousel-thumbnail-photo"
                lazy
              >
                <template #placeholder>
                  <b-skeleton />
                </template>
              </b-image>
            </div>
          </div>
        </template>
      </client-only>
    </div>
  </div>
</template>

<script>
import carousel from './OwlCarousel'

export default {
  components: {
    carousel
  },
  props: {
    images: {
      type: Array,
      required: true
    },
    name: {
      type: String,
      default: ''
    },
    zoom: {
      type: Boolean
    },
    zoomed: {
      type: Boolean
    },
    current: {
      type: Number,
      default: 0
    },
    startPosition: {
      type: Number,
      default: 0
    },
    withoutMainPhoto: {
      type: Boolean
    },
    withoutThumbnails: {
      type: Boolean
    },
    setThumbnailsPage: {
      type: Number,
      default: 5
    },
    responsive: {
      type: Object,
      default () {
        return {}
      }
    }
  },
  data () {
    return {
      startPositionThumbnail: 0,
      imgMain: null,
      imgThumbnail: null,
      thumbnails: {
        index: 0,
        page: 5
      },
      key: 0,
      count: 0
    }
  },
  watch: {
    images: {
      handler () {
        this.count = this.images.length
        this.key++
      },
      deep: true
    },
    imgMain (image) {
      this.$emit('current', image)
    },
    current (image) {
      this.change(image)
    }
  },
  mounted () {
    this.thumbnails.page = this.setThumbnailsPage
    this.count = this.images.length
    this.imgMain = this.startPosition
    if (!(this.startPosition === 0 && this.count > this.thumbnails.page)) {
      if (this.startPosition >= (this.count - this.thumbnails.page)) {
        this.startPositionThumbnail = this.count - this.thumbnails.page
        this.thumbnails.index = this.count - this.thumbnails.page
      } else {
        this.startPositionThumbnail = this.startPosition - 1
        this.thumbnails.index = this.startPosition - 1
      }
    }
  },
  methods: {
    showImage () {
      if (this.zoom) {
        this.$emit('zoom', this.imgMain)
      }
    },
    setImgMain (image) {
      this.imgMain = image
      if (this.withoutMainPhoto) {
        this.showImage()
      }
    },
    changedMain (image) {
      this.change(image.item.index)
    },
    change (mainImage) {
      this.imgMain = mainImage
      if (mainImage === 0) {
        this.imgThumbnail = mainImage
      } else if (mainImage === (this.count - 1)) {
        this.imgThumbnail = this.count - this.thumbnails.page
      } else if (this.thumbnails.index === mainImage) {
        this.imgThumbnail = mainImage - 1
      } else if ((this.thumbnails.index + this.thumbnails.page - 1) === mainImage) {
        this.imgThumbnail = this.thumbnails.index + 1
      } else if ((this.thumbnails.index - 1) === mainImage) {
        this.imgThumbnail = this.thumbnails.index - 1
      } else if (!(this.thumbnails.index <= mainImage <= (this.count - 1))) {
        this.imgThumbnail = mainImage
      }
    },
    changedThumbnail (image) {
      this.thumbnails = {
        index: image.item.index,
        page: image.page.size
      }
    }
  }
}
</script>
