<template>
  <div>
    <gallery
      :images="images"
      :current="current"
      :without-main-photo="withoutMainPhoto"
      :without-thumbnails="withoutThumbnails"
      :set-thumbnails-page="setThumbnailsPage"
      :responsive="responsive"
      :name="name"
      zoom
      zoomed
      @zoom="zoom"
    />
    <zoom-gallery ref="zoomGallery" :images="images" :active-slide.sync="current" />
  </div>
</template>

<script>
import Gallery from './Gallery'
import ZoomGallery from './Zoom/Modal'
export default {
  components: { Gallery, ZoomGallery },
  props: {
    images: {
      type: Array,
      required: true
    },
    name: {
      type: String,
      default: ''
    },
    withoutMainPhoto: {
      type: Boolean
    },
    withoutThumbnails: {
      type: Boolean
    },
    setThumbnailsPage: {
      type: Number,
      default: 5
    },
    responsive: {
      type: Object,
      default () {
        return {}
      }
    }
  },
  data () {
    return {
      isZoom: false,
      startPosition: 0,
      current: 0
    }
  },
  watch: {
    images: {
      handler () {
        this.current = 0
        this.startPosition = 0
      },
      deep: true
    }
  },
  methods: {
    zoom (image) {
      this.current = image
      this.$refs.zoomGallery.open()
    }
  }
}
</script>
