<template>
  <section v-if="ratesInTotal && ratesInTotal > 0" id="comments" class="page-comments">
    <div class="page-comments-gradient-bg" />
    <div class="page-comments-bg" />
    <div class="container-1000">
      <h2 class="text-center">
        {{ $t('ratings.title') }}
      </h2>
      <h3 class="text-center">
        {{ $t('ratings.subtitle') }}
      </h3>
      <p>
        {{ $t('ratings.text') }}
      </p>
      <b-loading :is-full-page="false" :active.sync="loading" :can-cancel="false" />
      <div class="ratings-photos">
        <div v-if="quantity">
          <div class="average-rating">
            <div class="average-rating-stars">
              <b-rate :value="averageRate" disabled />
              <span class="average-rating-value">
                {{ $t('comments.averageRating', { count: averageRate }) }}
              </span>
            </div>
            <p>
              {{ $t('comments.basedOn', { count: ratesInTotal }) }}
            </p>
          </div>

          <div class="rating-bars">
            <div v-for="row in Object.keys(quantity).reverse()" :key="row" class="rating-bar" @click="changeRating(row)">
              <div :class="row === rating ? 'has-text-weight-bold' : ''">
                {{ Number(row) }}
              </div>
              <b-progress
                :value="quantity[row].averageQuantity"
                :type="row === rating ? 'is-warning' : 'is-rating'"
                size="is-medium"
                class="bar"
              />
              <div :class="row === rating ? 'percentage has-text-weight-bold' : 'percentage'">
                {{ quantity[row].averageQuantity }}%
              </div>
            </div>
          </div>

          <div v-if="rating !== null" class="all-reviews">
            <b-button expanded class="all-reviews-button" @click.prevent="changeRating(null)">
              {{ $t('comments.showAllReviews') }}
            </b-button>
          </div>
        </div>
        <div>
          <span class="has-text-weight-semibold">{{ $t('comments.userImages') }}</span>
          <customer-gallery :images="images" />
        </div>
      </div>

      <div>
        <div class="reviews-list">
          <h4 class="title is-4">
            <span>
              {{ $t(`comments.reviews.${rating}`) }}
            </span>
            <span>({{ rating ? quantity[rating].quantity : ratesInTotal }})</span>
          </h4>
          <div class="reviews">
            <div v-for="(review, index) in data" :key="index" class="review">
              <div class="review-header">
                <avatar :name="review.user.displayName" :avatar="review.user.avatar" target="review" />
                <span class="review-date has-text-grey-light">{{ $moment(review.createdAt.date).format('LL') }}</span>
              </div>
              <div class="review-rating">
                <b-rate size="is-small" :value="review.star" disabled class="stars" />
                <div class="verified has-text-success">
                  <b-icon icon="checkmark" />
                  {{ $t('comments.verifiedPurchase') }}
                </div>
              </div>
              <div class="review-product has-text-grey">
                {{ review.productTitle }}
              </div>
              <div v-if="review.comment" class="review-comment">
                {{ review.comment.text }}
              </div>
              <gallery v-if="review.comment && review.comment.filePivots && review.comment.filePivots.length > 0" :images="review.comment.filePivots" without-main-photo :set-thumbnails-page="10" :responsive="{0: {items: 4}, 600: {items: 6}, 700: {items: 10}}" />
            </div>
            <div v-if="total === 0" class="review">
              <div class="no-reviews">
                <span class="has-text-grey-light">{{ $t('comments.noReviews') }}</span>
              </div>
            </div>
            <b-pagination
              v-if="total > 0"
              :total="total"
              :current="page"
              :per-page="perPage"
              :range-before="2"
              :range-after="2"
              class="m-t-20"
              :aria-next-label="$t('actions.nextPage')"
              :aria-previous-label="$t('actions.previousPage')"
              @change="onPageChange"
            />
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import gallery from 'marketplace-front-core/components/gallery/ZoomGallery'
import Avatar from 'marketplace-front-core/components/shared/Avatar'
import CustomerGallery from 'marketplace-front-core/components/comments/CustomerGallery'

export default {
  name: 'Comments',
  components: {
    gallery,
    Avatar,
    CustomerGallery
  },
  props: {
    rates: {
      type: Object,
      default: null
    },
    gallery: {
      type: Array,
      default () {
        return []
      }
    },
    identifier: {
      type: String,
      default: null
    }
  },
  data () {
    return {
      data: [],
      quantity: null,
      ratesInTotal: null,
      averageRate: null,
      rating: null,
      loading: false,
      total: null,
      page: 1,
      perPage: 10,
      images: []
    }
  },
  mounted () {
    if (this.rates) {
      this.data = this.rates.elements
      this.total = this.rates.all
      this.quantity = this.rates.quantity
      this.ratesInTotal = this.rates.ratesInTotal
      this.averageRate = this.rates.averageRate
    }

    if (this.gallery) {
      this.images = this.gallery
    }
  },
  methods: {
    loadAsyncData () {
      this.loading = true
      this.$api.comment.home({ page: this.page, limit: this.perPage, rating: this.rating, id: null, identifier: this.identifier })
        .then((res) => {
          this.loading = false
          this.data = res.elements
          this.total = res.all
          this.quantity = res.quantity
          this.ratesInTotal = res.ratesInTotal
          this.averageRate = res.averageRate
          this.scrollToCommentsTop()
        })
        .catch(() => {
          this.loading = false
          this.data = []
          this.total = 0
          this.quantity = null
          this.ratesInTotal = null
          this.averageRate = null
        })
    },
    onPageChange (page) {
      this.page = page
      this.loadAsyncData()
    },
    changeRating (rate) {
      if (!this.loading && rate !== this.rating) {
        this.rating = rate
        this.onPageChange(1)
      }
    },
    scrollToCommentsTop () {
      const element = document.getElementById('comments')
      if (element) {
        element.scrollIntoView({ behavior: 'smooth' })
        // const top = element.offsetTop
        // window.scrollTo({ top, behavior: 'smooth' })
      }
    }
  }
}
</script>

<style lang="scss" scoped>
$bg-gradient-top: #F5D9DA;
$bg-gradient-bottom: #F7F7F7;
$star-bg-color: #FCC01D;
.page-comments {
  position: relative;
  overflow: hidden;
  z-index: 2;
  .page-comments-gradient-bg {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    height: 600px;
    background: linear-gradient($bg-gradient-top, $bg-gradient-bottom);
    z-index: -1;
  }
  .page-comments-bg {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: $bg-gradient-bottom;
    z-index: -2;
  }
  .container-1000 {
    padding: 100px 0;
    z-index: 2;
    color: $text_color;
    @include mobile {
      padding: 64px 0;
    }
  }
  h2 {
    margin-bottom: 8px;
  }
  h3 {
    margin-bottom: 32px;
    font-size: 24px;
    font-weight: 600;
    color: $primary;
  }
  h3 + p {
    margin-bottom: 32px;
  }
  .ratings-photos {
    display: flex;
    gap: 20px;
    @include touch {
      flex-direction: column;
    }
    & > div {
      flex: 1;
    }
  }
  .average-rating {
    display: inline-flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 4px;
    padding: 16px 24px;
    border: 1px solid $text_color;
    border-radius: 16px;
    font-weight: 400 !important;
    @include touch {
      width: 100%;
    }
    .average-rating-stars {
      display: flex;
      align-items: center;
      gap: 12px;
      .rate {
        margin-bottom: 0;
        .rate-item {
          color: $star-bg-color !important;
        }
      }
      .average-rating-value {
        font-weight: 600;
      }
    }
  }
  .rating-bars {
    display: flex;
    flex-direction: column;
    gap: 8px;
    @include touch {
      width: 100%;
    }
    .rating-bar {
      margin-bottom: 0;
      .progress-wrapper {
        margin-bottom: 0;
      }
    }
  }
  .reviews-list {
    h4 {
      margin: 32px 0 24px 0;
      font-size: 20px;
    }
    .verified {
      font-weight: 600;
    }
  }
}
</style>
