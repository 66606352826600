<template>
  <div ref="categoryTiles" class="category-tiles">
    <category-tile v-for="(category, index) in filteredCategories" :key="index" :header-link="getLink(category)">
      <template v-if="getLink(category)" #header>
        <!-- Home -->
        <template v-if="checkType(category, 'plisy')">
          <pleated-blinds-icon />
        </template>
        <template v-if="checkType(category, 'roletki')">
          <roller-blinds-icon />
        </template>
        <template v-if="checkType(category, 'zaslony')">
          <curtains-icon />
        </template>

        <!-- Plisy -->
        <template v-if="checkType(category, 'bezinwazyjne')">
          <pleated-blinds-without-drilling-icon />
        </template>
        <template v-if="checkType(category, 'inwazyjne')">
          <pleated-blinds-with-drilling-icon />
        </template>
        <template v-if="checkType(category, 'dachowe')">
          <pleated-skylight-roof-blinds-icon />
        </template>

        <!-- Rolety -->
        <template v-if="checkType(category, 'DayAndNight')">
          <roller-blinds-day-night-icon />
        </template>
        <template v-if="checkType(category, 'Thermo')">
          <roller-blinds-thermo-blackout-icon />
        </template>

        <!-- Zaslony -->
        <template v-if="checkType(category, 'Century')">
          <curtains-icon />
        </template>
        <template v-if="checkType(category, 'Millenium')">
          <curtains-icon />
        </template>

        <!-- Relax -->
        <template v-if="checkType(category, 'Spannbettlaken')">
          <relax-icon />
        </template>
        <template v-if="checkType(category, 'Kissenbezüge')">
          <relax-icon />
        </template>

        <div class="category-header-container">
          <h3 v-if="category.description">
            {{ category.description.name }}
          </h3>
          <rating-average :average="category.rate.rate ? category.rate.rate : 5" :total="category.rate.count" />
        </div>
      </template>
      <template #links>
        <nuxt-link
          v-for="(link, i) in category.landingPages"
          :key="i"
          :to="localePath({
            name: 'product',
            params: {
              productName: toSlugTitle({name: link.offerProductName}),
              productId: link.offerProduct}
          })"
          class="category-link"
        >
          <div class="category-link-value">
            <template v-if="link.name">
              <span>
                {{ link.name }}
              </span>
            </template>
            <!-- TODO: Do wyjebania jak bedzie pelna zwrotka z backendu - brakuje category.landingPages[0].name -->
            <template v-else>
              <span>
                {{ category.description.name }}
              </span>
            </template>
            <span v-if="link.new" class="category-link-new">
              {{ $t('project.landingPage.new') }}
            </span>
          </div>
          <img src="~/assets/images/arrow-cta.svg" alt="arrow" class="category-link-arrow">
        </nuxt-link>
      </template>
      <template #image>
        <nuxt-link v-if="getLink(category) && category.imageGroup" :to="getLink(category)">
          <b-image
            ratio="1by1"
            :src="getImageBySize(category.imageGroup, 'big').src"
            :webp-fallback="getImageBySize(category.imageGroup, 'big').webpFallback"
            class="skeleton-image-placeholder"
            :alt="category.offerProductName || 'schuette product'"
            lazy
          >
            <template #placeholder>
              <b-skeleton />
            </template>
          </b-image>
        </nuxt-link>
        <template v-else>
          <b-image
            ratio="1by1"
            :src="getImageBySize(category.imageGroup, 'big').src"
            :webp-fallback="getImageBySize(category.imageGroup, 'big').webpFallback"
            class="skeleton-image-placeholder"
            :alt="category.offerProductName || 'schuette product'"
            lazy
          >
            <template #placeholder>
              <b-skeleton />
            </template>
          </b-image>
        </template>
      </template>
      <template v-if="full" #colors>
        <div v-for="(collection, iCollection) in category.tree" :key="iCollection" class="category-collection-colors">
          <div class="collection-name">
            {{ collection.name }}
          </div>
          <div class="collection-colors-container">
            <div class="collection-colors">
              <nuxt-link
                v-for="(color, iColor) in getColors(collection.colors)"
                :key="iColor"
                :aria-label="color.offerProductName"
                :to="localePath({
                  name: 'product',
                  params: {
                    productName: toSlugTitle({name: color.offerProductName}),
                    productId: color.offerProduct}
                })"
              >
                <div
                  class="collection-color"
                  :style="`background-color: ${color.hex || '#FFF'}`"
                />
              </nuxt-link>
            </div>
          </div>
        </div>
      </template>
      <template v-if="full" #features>
        <slot
          name="features"
          :identifier="category.identifier"
          :identifierSeo="category.description ? category.description.name_seo : null"
        />
      </template>
      <template #buttons>
        <!-- <nuxt-link v-if="getLink(category)" :to="getLink(category)"> -->
        <nuxt-link v-if="getLinkProduct(category)" :to="getLinkProduct(category)">
          <b-button type="is-primary" class="cta-primary" expanded rounded icon-right="arrow-right-bold">
            {{ $t('project.landingPage.browseAll') }}
          </b-button>
        </nuxt-link>
      </template>
    </category-tile>
  </div>
</template>

<script>
import CategoryTile from '~/components/shared/CategoryTile'
import RatingAverage from '~/components/layout/RatingAverage'

import PleatedBlindsIcon from '~/assets/images/category/pb.svg?inline'
import RollerBlindsIcon from '~/assets/images/category/rb.svg?inline'
import CurtainsIcon from '~/assets/images/category/cr.svg?inline'
import RelaxIcon from '~/assets/images/category/ac.svg?inline'

import PleatedBlindsWithoutDrillingIcon from '~/assets/images/category/pbwd.svg?inline'
import PleatedBlindsWithDrillingIcon from '~/assets/images/category/pbd.svg?inline'
import PleatedSkylightRoofBlindsIcon from '~/assets/images/category/psrb.svg?inline'

import RollerBlindsDayNightIcon from '~/assets/images/category/rbdn.svg?inline'
import RollerBlindsThermoBlackoutIcon from '~/assets/images/category/rbtb.svg?inline'

import equalHeight from '~/mixin/equalHeight'

export default {
  components: {
    CategoryTile,
    RatingAverage,
    PleatedBlindsIcon,
    RollerBlindsIcon,
    CurtainsIcon,
    RelaxIcon,
    PleatedBlindsWithoutDrillingIcon,
    PleatedBlindsWithDrillingIcon,
    PleatedSkylightRoofBlindsIcon,
    RollerBlindsDayNightIcon,
    RollerBlindsThermoBlackoutIcon
  },
  mixins: [equalHeight],
  props: {
    categories: {
      type: Array,
      default () {
        return []
      }
    },
    full: {
      type: Boolean
    },
    home: {
      type: Boolean
    }
  },
  data () {
    return {
      linksHeightStyleAdded: false
    }
  },
  computed: {
    // TODO: też do poprawy jak bedzie dobrze zwracane bo na home trzeba wyjebac zwrotke kategorii relax
    filteredCategories () {
      return this.home ? this.categories.filter(category => category.identifier !== 'relax') : this.categories
    }
  },
  mounted () {
    this.resize()
    window.addEventListener('resize', this.resize)
  },
  destroyed () {
    window.removeEventListener('resize', this.resize)
  },
  methods: {
    resize () {
      if (window.innerWidth < 1024 && this.linksHeightStyleAdded) {
        this.setEqualHeight(this.$refs.categoryTiles, '.category-links', false)
        this.linksHeightStyleAdded = false
      } else if (window.innerWidth >= 1024 && !this.linksHeightStyleAdded) {
        this.setEqualHeight(this.$refs.categoryTiles, '.category-links', true)
        this.linksHeightStyleAdded = true
      }
    },
    getColors (colors) {
      if (colors) {
        return colors.filter(x => x.hex)
      }
      return []
    },
    getLink (category) {
      if (this.home) {
        // return this.getLinkProduct(category)
        return this.getLinkCategories(category.identifier)
      } else {
        return this.getLinkProduct(category)
      }
    },
    getLinkCategories (identifier) {
      switch (identifier) {
        case 'plisy':
          return this.localePath('categories-pleated-blinds')
        case 'roletki':
          return this.localePath('categories-roller-blinds')
        case 'zaslony':
          return this.localePath('categories-curtains')
        default:
          return null
      }
    },
    getLinkProduct (category) {
      if (category && category.offerProductName && category.offerProduct) {
        return this.localePath({
          name: 'product',
          params: {
            productName: this.toSlugTitle({ name: category.offerProductName }),
            productId: category.offerProduct
          }
        })
      }
      return null
    },
    checkType (category, type) {
      if (this.home) {
        return category.identifier === type
      } else {
        return category &&
        category.description &&
        category.description.name_seo &&
        category.description.name_seo === type
      }
    }
  }
}
</script>
