<script>
export default {
  methods: {
    setEqualHeight (destination, target, value) {
      const items = destination.children
      if (value) {
        let maxHeight = 0
        for (const item of items) {
          const div = item.querySelector(target)
          maxHeight = div.clientHeight > maxHeight ? div.clientHeight : maxHeight
        }
        for (const item of items) {
          const div = item.querySelector(target)
          div.setAttribute('style', `height: ${maxHeight}px;`)
        }
      } else {
        for (const item of items) {
          const div = item.querySelector(target)
          div.removeAttribute('style')
        }
      }
    }
  }
}
</script>
