<template>
  <div class="carousel">
    <span v-show="showNav" class="prev" @click="prev">
      <div class="arrow-icon">
        <b-icon icon="chevron-left" />
      </div>
    </span>
    <div :id="elementHandle" :class="['owl-carousel', 'owl-theme']">
      <slot />
    </div>

    <span v-show="showNav" class="next" @click="next">
      <div class="arrow-icon">
        <b-icon icon="chevron-right" />
      </div>
    </span>
  </div>
</template>

<script>
import 'owl.carousel/dist/assets/owl.carousel.css'
import 'owl.carousel/dist/assets/owl.theme.default.css'
import $ from 'jquery'
if (process.browser) {
  window.jQuery = $
  require('owl.carousel')
}

export default {
  name: 'OwlCarousel',
  props: {
    keyboard: {
      type: Boolean
    },
    showNav: {
      type: Boolean
    },
    items: {
      type: Number,
      default: 3
    },
    change: {
      type: Number,
      default: null
    },
    startPosition: {
      type: Number,
      default: 0
    },
    responsive: {
      type: Object,
      default () {
        return {}
      }
    }
  },
  data () {
    return {
      owl: null,
      elementHandle: 'carousel_' + this.generateUniqueId()
    }
  },
  watch: {
    change (item) {
      this.owlTo(item)
    }
  },
  mounted () {
    if (this.keyboard) {
      window.addEventListener('keydown', this.keydown)
    }

    this.owl = $('#' + this.elementHandle).owlCarousel({
      items: this.items,
      margin: 10,
      loop: false,
      nav: false,
      dots: false,
      rewind: true,
      responsive: this.responsive,
      startPosition: this.startPosition
    })

    this.owl.on('changed.owl.carousel', (event) => {
      this.$emit('changed', event)
    })
  },
  destroyed () {
    if (this.keyboard) {
      window.removeEventListener('keydown', this.keydown)
    }
  },
  methods: {
    keydown (e) {
      if (e.keyCode === 39) {
        this.next()
      } else if (e.keyCode === 37) {
        this.prev()
      }
    },
    owlTo (item) {
      this.owl.trigger('to.owl.carousel', item)
    },
    next () {
      this.owl.trigger('next.owl.carousel')
    },
    prev () {
      this.owl.trigger('prev.owl.carousel')
    },
    generateUniqueId () {
      return Math.random().toString(36).substring(2, 15)
    }
  }
}

</script>
