<template>
  <div>
    <div ref="content" class="products-comments-images">
      <template v-if="filteredImages.images">
        <div v-for="(image, index) in filteredImages.images" :key="image.id" class="comment-image">
          <b-image

            ratio="1by1"
            :src="getImageBySize(image.file, 'small').src"
            :webp-fallback="getImageBySize(image.file, 'small').webpFallback"
            class="skeleton-image-placeholder carousel-main-photo"
            :class="{'zoom': true}"
            alt="photo"
            lazy
            @click.native="showImage(index)"
          >
            <template #placeholder>
              <b-skeleton />
            </template>
          </b-image>
        </div>
        <div v-if="filteredImages.hiddenImages" class="comment-count" @click="showImage(filteredImages.images.length)">
          +{{ filteredImages.hiddenImages }}
        </div>
      </template>
    </div>
    <zoom-gallery ref="zoomGallery" :images="images" :active-slide.sync="current" />
  </div>
</template>

<script>
import ZoomGallery from '../gallery/Zoom/Modal'
export default {
  components: {
    ZoomGallery
  },
  props: {
    images: {
      type: Array,
      default: () => []
    }
  },
  data () {
    return {
      current: null,
      maxImages: 0
    }
  },
  computed: {
    filteredImages () {
      if (this.images.length > this.maxImages) {
        const hiddenImages = (this.images.length - this.maxImages) + 1
        return { images: this.images.slice(0, this.maxImages - 1), hiddenImages }
      } else {
        return { images: this.images, hiddenImages: 0 }
      }
    }
  },
  destroyed () {
    window.removeEventListener('resize', this.resize)
  },
  mounted () {
    this.resize()
    window.addEventListener('resize', this.resize)
  },
  methods: {
    showImage (index) {
      this.current = index || 0
      this.$refs.zoomGallery.open()
    },
    resize () {
      const width = this.$refs.content.clientWidth
      if (width) {
        this.maxImages = Math.floor(width / 90) * 2
      } else {
        this.maxImages = 0
      }
    }
  }
}
</script>
