<template>
  <div class="category-tile">
    <a v-if="$slots.header" :href="headerLink" class="header-link" @click.prevent="$router.push(headerLink)">
      <slot name="header" />
    </a>
    <div v-if="$slots.links" class="category-links">
      <slot name="links" />
    </div>
    <div v-if="$slots.image" class="category-image">
      <slot name="image" />
    </div>
    <div v-if="$slots.colors" class="category-colors">
      <slot name="colors" />
    </div>
    <div v-if="$slots.features" class="category-features">
      <slot name="features" />
    </div>
    <div v-if="$slots.buttons" class="category-buttons">
      <slot name="buttons" />
    </div>
  </div>
</template>

<script>

export default {
  name: 'CategoryTile',
  props: {
    headerLink: {
      type: String,
      default: ''
    }
  }
}
</script>

<style lang="scss" scoped>
$bgColor: #FAFAFA;
$bgColorHover: #F2F2F2;
$borderGray: #D9D9D9;
$ctaBlue: #1F53B7;
$primaryLightBg: #FCE5E6;

.svg {
  display: inline-block;
  width: 32px;
  height: 32px;
}
.category-tile {
  width: 100%;
  background-color: $bgColor;
  border-radius: 16px;
  overflow: hidden;
  header, :deep(.header-link) {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 16px;
    padding: 20px;
    border-bottom: 1px solid $borderGray;
    color: $ctaBlue;
    transition: background-color 200ms, color 200ms ease-in-out;
    &:hover {
      background-color: $bgColorHover;
      color: $text_color;
    }
    h3 {
      font-size: 20px;
      font-weight: 600;
      line-height: 1.3;
      &.is-small {
        font-size: 16px;
      }
    }
    svg, video {
      height: 44px;
    }
    svg {
      flex-shrink: 0;
    }
    .category-rating {
      display: flex;
      align-items: center;
      gap: 4px;
      .rate {
        margin-bottom: 0;
      }
      .rating-value {
        font-size: 12px;
      }
    }
  }
  .category-links {
    display: flex;
    flex-direction: column;
    // gap: 8px;
    margin: 20px;
    :deep(.category-link) {
      display: flex;
      align-items: flex-end;
      justify-content: space-between;
      padding: 4px 0;
      // gap: 6px;
      color: $ctaBlue;
      &:hover {
        .category-link-value {
          text-decoration: underline;
          transition: background-color 200ms, color 200ms ease-in-out;
        }
        .category-link-new {
          text-decoration: none;
        }
        .category-link-arrow {
          animation-name: arrow-animation;
          animation-duration: 300ms;
          animation-fill-mode: forwards;
        }
      }
      .category-link-value {
        display: inline-block;
        .category-link-new {
          display: inline-block;
          padding: 2px 6px;
          background-color: $primaryLightBg;
          border-radius: 6px;
          font-size: 10px;
          color: $primary;
        }
      }
      .category-link-arrow {
        margin-bottom: 6px;
      }
    }
  }
  .category-image {
    position: relative;
    width: 100%;
  }
  .category-colors {
    display: flex;
    flex-direction: column;
    gap: 20px;
    margin: 20px;
    .category-collection-colors {
      display: flex;
      flex-direction: column;
      gap: 12px;
      .collection-name {
        font-weight: 600;
        text-align: center;
      }
      .collection-colors-container {
        display: flex;
        justify-content: center;
        :deep(.collection-colors) {
          display: grid;
          grid-template-columns: repeat(7, 28px);
          gap: 8px;
          .collection-color {
            width: 28px;
            height: 28px;
            border-radius: 50%;
            box-shadow: 0 2px 2px rgba(#000000, 0.2);
          }
        }
      }
    }
  }
  .category-features {
    display: flex;
    flex-direction: column;
    gap: 16px;
    margin: 24px;
    .category-feature {
      display: flex;
      flex-direction: column;
      gap: 12px;
      padding-top: 16px;
      border-top: 1px solid $borderGray;
      .feature-icons {
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 4px;
        svg {
          display: inline-block;
          width: 32px;
          height: 32px;
        }
      }
      .feature-description {
        font-size: 14px;
        text-align: center;
      }
    }
  }
  .category-buttons {
    margin: 24px;
  }
}
@keyframes arrow-animation {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(4px);
  }
}
</style>
