<template>
  <div class="category-tiles is-hidden-desktop">
    <a v-for="(category, index) in categories" :key="index" :href="getLink(category)" @click.prevent="$router.push(getLink(category))">
      <!-- TODO: do wyjebanie jak backend ogarnie tlumaczenia -->
      <category-tile
        :name="checkType(category, 'relax') ? $t('project.layout.footer.menu.sb') : category.description.name"
        :rating-average="category.rate.rate ? category.rate.rate : 5"
        :rating-total="category.rate.count"
      >
        <template #icon>
          <!-- Home -->
          <template v-if="checkType(category, 'plisy')">
            <lazy-video :video="{video: PleatedBlindsVideo}" :video-mov="{video: PleatedBlindsVideoMov}" type="video/webm" alt="pleated blinds" />
          </template>
          <template v-if="checkType(category, 'roletki')">
            <lazy-video :video="{video: RollerBlindsVideo}" :video-mov="{video: RollerBlindsVideoMov}" type="video/webm" alt="roller blinds" />
          </template>
          <template v-if="checkType(category, 'zaslony')">
            <lazy-video :video="{video: CurtainsVideo}" :video-mov="{video: CurtainsVideoMov}" type="video/webm" alt="curtains" />
          </template>
          <template v-if="checkType(category, 'relax')">
            <lazy-video :video="{video: RelaxVideo}" :video-mov="{video: RelaxVideoMov}" type="video/webm" alt="relax" />
          </template>

          <!-- Plisy -->
          <template v-if="checkType(category, 'bezinwazyjne')">
            <pleated-blinds-without-drilling-icon />
          </template>
          <template v-if="checkType(category, 'inwazyjne')">
            <pleated-blinds-with-drilling-icon />
          </template>
          <template v-if="checkType(category, 'dachowe')">
            <pleated-skylight-roof-blinds-icon />
          </template>

          <!-- Rolety -->
          <template v-if="checkType(category, 'DayAndNight')">
            <roller-blinds-day-night-icon />
          </template>
          <template v-if="checkType(category, 'Thermo')">
            <roller-blinds-thermo-blackout-icon />
          </template>

          <!-- Zaslony -->
          <template v-if="checkType(category, 'Century')">
            <curtains-icon />
          </template>
          <template v-if="checkType(category, 'Millenium')">
            <curtains-icon />
          </template>

          <!-- Relax -->
          <template v-if="checkType(category, 'Spannbettlaken')">
            <lazy-video :video="{video: RelaxVideo}" :video-mov="{video: RelaxVideoMov}" type="video/webm" alt="relax" />
          </template>
          <template v-if="checkType(category, 'Kissenbezüge')">
            <lazy-video :video="{video: RelaxVideo}" :video-mov="{video: RelaxVideoMov}" type="video/webm" alt="relax" />
          </template>
        </template>
      </category-tile>
    </a>
  </div>
</template>

<script>

import LazyVideo from 'marketplace-front-core/components/shared/LazyVideo.vue'
import CategoryTile from '~/components/layout/CategoryTile.vue'

// import PleatedBlindsIcon from '~/assets/images/category/pb.svg?inline'
// import RollerBlindsIcon from '~/assets/images/category/rb.svg?inline'
import CurtainsIcon from '~/assets/images/category/cr.svg?inline'
// import RelaxIcon from '~/assets/images/category/ac.svg?inline'

import PleatedBlindsVideo from '~/static/images/pleated-blinds-video.webm'
import PleatedBlindsVideoMov from '~/static/images/pleated-blinds-video.mov'
import RollerBlindsVideo from '~/static/images/roller-blinds-video.webm'
import RollerBlindsVideoMov from '~/static/images/roller-blinds-video.mov'
import CurtainsVideo from '~/static/images/curtains-video.webm'
import CurtainsVideoMov from '~/static/images/curtains-video.mov'
import RelaxVideo from '~/static/images/relax-video.webm'
import RelaxVideoMov from '~/static/images/relax-video.mov'

import PleatedBlindsWithoutDrillingIcon from '~/assets/images/category/pbwd.svg?inline'
import PleatedBlindsWithDrillingIcon from '~/assets/images/category/pbd.svg?inline'
import PleatedSkylightRoofBlindsIcon from '~/assets/images/category/psrb.svg?inline'

import RollerBlindsDayNightIcon from '~/assets/images/category/rbdn.svg?inline'
import RollerBlindsThermoBlackoutIcon from '~/assets/images/category/rbtb.svg?inline'

export default {
  name: 'CategoryTiles',
  components: {
    LazyVideo,
    CategoryTile,
    PleatedBlindsWithoutDrillingIcon,
    PleatedBlindsWithDrillingIcon,
    PleatedSkylightRoofBlindsIcon,
    RollerBlindsDayNightIcon,
    RollerBlindsThermoBlackoutIcon,
    CurtainsIcon
    // PleatedBlindsIcon,
    // RollerBlindsIcon,
    // RelaxIcon
  },
  props: {
    categories: {
      type: Array,
      default () {
        return []
      }
    },
    home: {
      type: Boolean
    }
  },
  data () {
    return {
      PleatedBlindsVideo,
      PleatedBlindsVideoMov,
      RollerBlindsVideo,
      RollerBlindsVideoMov,
      CurtainsVideo,
      CurtainsVideoMov,
      RelaxVideo,
      RelaxVideoMov
    }
  },
  methods: {
    checkType (category, type) {
      if (this.home) {
        return category.identifier === type
      } else {
        return category &&
        category.description &&
        category.description.name_seo &&
        category.description.name_seo === type
      }
    },
    getLinkCategories (identifier) {
      switch (identifier) {
        case 'plisy':
          return this.localePath('categories-pleated-blinds')
        case 'roletki':
          return this.localePath('categories-roller-blinds')
        case 'zaslony':
          return this.localePath('categories-curtains')
        case 'relax':
          return this.localePath('categories-relax')
        default:
          return null
      }
    },
    getLink (category) {
      if (this.home) {
        return this.getLinkCategories(category.identifier)
      } else {
        return this.localePath({
          name: 'product',
          params: {
            productName: this.toSlugTitle({ name: category.offerProductName }),
            productId: category.offerProduct
          }
        })
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.category-tiles {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 20px;
  margin-bottom: 50px;
  @include touch {
    flex-direction: column;
    gap: 10px;
    margin-bottom: 32px;
  }
  a {
    width: 100%;
    color: $text_color;
  }
}
</style>
