<template>
  <div v-if="active" class="gallery-modal" tabindex="-1">
    <div class="gallery-modal-background" />
    <list
      :images="images"
      :active-slide="activeSlide"
      @close="() => active = false"
      @updateActiveSlide="x => $emit('update:activeSlide', x)"
    />
    <div class="gallery-modal-close" @click.prevent="active = false">
      <b-button icon-left="clear" size="is-medium" />
    </div>
  </div>
</template>

<script>

export default {
  components: {
    List: () => { if (process.client) { return import('./List.vue') } }
  },
  props: {
    activeSlide: {
      type: Number,
      default: 0
    },
    images: {
      type: Array,
      default: () => []
    }
  },
  data () {
    return {
      active: false
    }
  },
  watch: {
    active (val) {
      this.toggleClass(val)
    }
  },
  mounted () {
    this.toggleClass(this.active)
  },
  beforeDestroy () {
    this.toggleClass(false)
  },
  methods: {
    open () {
      this.active = true
    },
    toggleClass (active) {
      if (active) {
        document.documentElement.classList.add('is-clipped')
      } else {
        document.documentElement.classList.remove('is-clipped')
      }
    }
  }
}
</script>
